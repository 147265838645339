import ModalLayout from "../ModalLayout";
import { ModalContent } from "./modalContent";
import { useTheme } from "styled-components";

const ModalClientInfo = ({
  // props
  data = {},
  restaurants = [],
  isOpen = false,
  closeModalClientInfo = () => { },
}) => {
  const theme = useTheme();
  return (
    <ModalLayout
      theme={theme}
      modalIsOpen={isOpen}
      closeModal={closeModalClientInfo}
      dopClass="m-client-info"
    >
      {isOpen && (
        <div className="cont-modal-sc">
          <ModalContent
            data={data}
            restaurants={restaurants}
          />
        </div>
      )}
    </ModalLayout>
  );
}

export default ModalClientInfo;