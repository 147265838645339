import { isString } from "lodash";
import api from "../../apiSingleton";
import moment from 'moment';

import {
  ACTIVE_ORDERS_CALLCENTER,
  ACTIVE_ORDERS_KITCHEN,
  ACTIVE_ORDERS_COURIER,
  UPDATE_FORM_SEARCH_ORDERS,
  UPDATE_STATUS_SEARCH,
  UPDATE_LOAD_PAGE,
  UPDATE_FILTER_PAGE_KITCHEN,
} from "../constants/listings";

import {
  updadeLoadPageClients,
  closeModalFilter
} from './view';

import {
  addNotification
} from './notifications';

export function getAllActiveOrders(params = {}, isFilter = false, status = null, signal = undefined) {
  return async (dispatch, state) => {
    const { listings: { searchValue, statusSearch } } = state();
    const today = moment(new Date()).format("YYYY-MM-DD");

    let dataParams = {
      ...params,
    }

    if (params.date_from === today && params.date_to === today) {
      // console.debug('getAllActiveOrders - date_from and date_to are same current day, removing from params');
      delete dataParams.date_from;
      delete dataParams.date_to;
    }

    if (statusSearch) {
      dataParams.q = searchValue;
    } else {
      delete dataParams.q;
    }

    isString(status) && (dataParams.status = status);

    isFilter && (dispatch(closeModalFilter(false)));
    isFilter && dispatch(updadeLoadPageClients(true));

    await dispatch(updatePageLoad(true));
    const res = await api.listings.getAllActiveOrders({
      ...dataParams
    }, signal);

    const { data: orders, count: ordersCount } = res;
    if (!!orders && !!ordersCount) {
      await dispatch({
        type: ACTIVE_ORDERS_CALLCENTER,
        payload: { orders, ordersCount },
      });
    } else if (!signal?.aborted) {
      await dispatch(addNotification({ war: [res.message || 'Помилка пошуку замовлень'] }, 'warn'));
    }

    await dispatch(updatePageLoad(false));
    isFilter && dispatch(updadeLoadPageClients(false));
  };
}

export function downloadExcelOrder(params = {}, status = null, setLoading = () => { }) {
  return async (dispatch) => {
    let dataParams = {
      ...params,
    }

    isString(status) && (dataParams.status = status);
    setLoading(true);

    const res = await api.listings.downloadExcelOrder(dataParams);

    if (res.status == "Ok") {
      const link = document.createElement('a');

      link.href = res.data.file;
      link.setAttribute(
        'download',
        `statistics.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setLoading(false);
    } else {
      dispatch(addNotification(res.errors, 'error'));
      setLoading(false);
    }
  }
}

export function getCouriersOrders() {
  return async (dispatch) => {
    const data = await api.listings.getCouriersOrders();

    await dispatch({
      type: ACTIVE_ORDERS_COURIER,
      payload: data,
    });
  };
}

export const getOrderItems = () => {
  return async (dispatch) => {
    const data = await api.listings.getOrderItems();

    await dispatch({
      type: ACTIVE_ORDERS_KITCHEN,
      payload: { data },
    });
  };
};

export const updateFormSearchOrders = (value) => {
  return {
    type: UPDATE_FORM_SEARCH_ORDERS,
    payload: value,
  }
}

export const handleІSybmitSearchOrders = (value) => {
  return {
    type: UPDATE_STATUS_SEARCH,
    payload: value,
  }
}

export const updatePageLoad = (status) => {
  return ({
    type: UPDATE_LOAD_PAGE,
    payload: status,
  })
}

export const updateFilter = (item, data) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_FILTER_PAGE_KITCHEN,
      payload: { item, data }
    });
  }
}