import React from "react";

// constants
import { CRM_VERSION } from "config";

export const useMaintenance = (crmOptions) => React.useMemo(() => {
  const {
    version,
    technicalWorks: {
      status: open = false,
      message,
    }
  } = crmOptions;

  if (CRM_VERSION !== version) {
    return {
      open: true,
      showReloadButton: true,
      message: `Ваша версія (${CRM_VERSION}) застаріла. Наразі доступна актуальна версія ${version}. Рекомендуємо оновитися для забезпечення найкращої роботи та доступу до нових функцій!`,
    };
  }

  return { open, message };

}, [crmOptions]);
