import { useEffect } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";


import { RADIO_SCHEME_ACTIONS } from "constants/schemes";

const Home = ({
    // state
    crmOptionsForm: {
        version: crmVersion = "",
        technicalWorks: {
            message: technicalWorksMessage = "",
            status: technicalWorksStatus = false,
        } = {}
    },
    isLoadPage,
    // dispatch
    updateFormCRMOptions = () => { },
    saveFormCRMOptions = () => { },
    getFormCRMOptions = () => { },
}) => {

    useEffect(() => {
        getFormCRMOptions();
    }, [getFormCRMOptions]);

    return (
        <div className={styles.d_max_1023}>
            {
                isLoadPage ? (
                    <LoadingCircl />
                ) : (
                    <>
                        <div className={styles.d_grid}>
                            <div className={`${styles.title_row} ${styles.marg_bot}`}>Налаштування CRM</div>

                            <div className={styles.wr_drop}>
                                <TextInput
                                    title="Версія CRM"
                                    type="text"
                                    value={crmVersion}
                                    isStyle={true}
                                    onChange={(e) => updateFormCRMOptions("version", e.target.value)}
                                />
                            </div>

                            <div className={styles.wr_drop}>
                                <TextInput
                                    title="Повідомлення про тех. роботи"
                                    type="text"
                                    value={technicalWorksMessage}
                                    isStyle={true}
                                    onChange={(e) => updateFormCRMOptions("technicalWorks.message", e.target.value)}
                                />
                            </div>

                            <div className={styles.wr_drop}>
                                <Radio
                                    colors={"#000"}
                                    items={RADIO_SCHEME_ACTIONS}
                                    label="Проводяться тех. роботи"
                                    valueKey="status_client"
                                    value={technicalWorksStatus}
                                    onChange={(e) => updateFormCRMOptions("technicalWorks.status", e.value.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.row_btn_tow}>
                            <div className={styles.item_btn}>
                                <ButtonIcon
                                    onClick={saveFormCRMOptions}
                                    title='Зберегти'
                                    colorBg="green"
                                    icon={<SaveIcon />}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}


export default Home;
