import React, { useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import history from "constants/history";
import { useMaintenance } from "hooks/useMaintenance";

import Navigation from "./navigation/Navigation";
import Notifications from "components/notifications";
import MaintenanceAlert from "components/MaintenanceAlert";
import ModalConfirmLogout from "components/modals/ModalConfirmLogout";
import ModalBugReport from "components/modals/ModalBugReport";

import Snowfall from "react-snowfall";

const CommonLayout = ({
  children,
  user,
  crmOptions,
  tabs,
  openModalConfirmLogout,
  openModalBugReport,
  getCRMOptions,
}) => {
  const theme = useTheme();
  const maintenance = useMaintenance(crmOptions);
  const displaySnow = useMemo(() => {
    const isWinter = [11, 0, 1].includes(new Date().getMonth());
    return isWinter && history?.location?.pathname?.includes('/callcenter');
  }, [history?.location?.pathname]); // display snow for callcenter

  useEffect( // check for crm options
    () => {
      const interval = setInterval(getCRMOptions, 60000);
      return () => clearInterval(interval);
    },
    [getCRMOptions],
  );

  return (
    <>
      <Navigation
        user={user}
        tabs={tabs}
        activeTab={history?.location.pathname}
        logout={openModalConfirmLogout}
        openModalBugReport={openModalBugReport}
      />

      <MaintenanceAlert
        isOpen={maintenance.open}
        message={maintenance.message}
        showReloadButton={maintenance.showReloadButton}
      />

      <Container theme={theme} $maintenance={maintenance.open}>
        {children}
      </Container>

      <Notifications />
  
      <ModalConfirmLogout />
      <ModalBugReport />

      {displaySnow && <Snowfall style={{ zIndex: 999 }} />}
    </>
  );
};

const Container = styled.section`
  padding: ${(p) => 58 + (p.$maintenance ? 35 : 0)}px 14px 14px 14px;
  min-height: calc(100vh - 50px);
`;

export default React.memo(CommonLayout);
