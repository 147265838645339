import { MapContainer, TileLayer } from "react-leaflet";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import "leaflet/dist/leaflet.css";

// import MapIcon from "@mui/icons-material/Map";
import ClockIcon from "@mui/icons-material/Watch";

import iconHome from "assets/icons/marker-home.svg";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { updateOrderAddress } from "store/actions/order";

import { MapMarkerLeaflet, MapCenterObserver, MapPolygonLeaflet } from "components/mapes";
import { LoadingModal } from "../../../../loadingOrder/loading";
import ModalDelete from "components/modals/ModalDelete";

import {
  getGeodataAddress,
  getGeodataCoordinates,
} from "utils/helpers/geocoding";

import { citySearch, citySearchNameUa, suburdSearchNameUa } from "utils/mappers/cities";
import { searchActiveKitchenCityPolygons } from "utils/mappers/kitchens";
import { addNotification } from "store/actions/notifications";

// определить кординаты по адрессу
export const searchForCoorinates = async (address, cities, bounds, dispatch) => {
  const { city_sync_id, street, house_number, settlement_id } = address;

  const city = citySearch(city_sync_id, cities);
  const settlement = citySearch(
    settlement_id,
    (city?.settlements || []),
  );

  console.debug('searchForCoordinates', {
    city,
    settlement,
  });

  if (city) {
    const data = await getGeodataCoordinates(
      city,
      settlement,
      street,
      house_number,
      bounds,
    );

    if (data) {
      await dispatch(updateOrderAddress("latitude", data?.lat));
      await dispatch(updateOrderAddress("longitude", data?.lng));
    } else {
      dispatch(updateOrderAddress("latitude", undefined));
      dispatch(updateOrderAddress("longitude", undefined));
      dispatch(addNotification({ error: ["Адресу не знайдено"] }, 'error'));
    }
  }
};

// получения и передача кординат карте
const parseCoordinates = (latitude, longitude, cities) => {
  let cityDefault = null;

  if (!!latitude && !!longitude)
    return {
      lat: +parseFloat(latitude),
      lng: +parseFloat(longitude),
    };

  cityDefault = citySearch('lviv', cities);

  if (!!cityDefault.latitude && !!cityDefault.longitude) {

    return {
      lat: +cityDefault.latitude,
      lng: +cityDefault.longitude,
    };
  }

  return {
    lat: 49.84287298912712,
    lng: 24.027133941126525,
  };
};

const defaultStyle = {
  height: "100%",
  width: "100%",
  borderRadius: 10
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  clicableIcons: false,
  KeyboardShortcuts: false,
  scrollwheel: true,
  disablDoubleClickZoom: true,
  fullscreenControll: false,
}

const CONFIRM_ADDRESS_MODAL_INITIAL_STATE = {
  isOpen: false,
  isLoad: false,
  lat: null,
  lng: null
};

const TabMap = ({ isEdit }) => {
  const dispatch = useDispatch();
  const [confirmAddressModal, setConfirmAddressModal] = useState(CONFIRM_ADDRESS_MODAL_INITIAL_STATE);
  const closeConfirmAddressModal = () => setConfirmAddressModal(CONFIRM_ADDRESS_MODAL_INITIAL_STATE);

  const {
    order: {
      data: {
        address,
        address: { latitude, longitude },
      },
      deliveryTime: {
        time: deliveryTime,
        loading: deliveryTimeLoading,
      },
    },
    settings: { cities },
    kitchens: { kitchensListCity },
    loadings: { loadModalEdition }
  } = useSelector((store) => store);

  const cityPolygonBounds = useSelector(({ settingsProvings }) => {
    return settingsProvings.cityPolygonBounds[address.city_sync_id];
  });

  // получить все пригороды по городу
  const settlementList = () => {
    let list = citySearch(address?.city_sync_id, cities)?.settlements;

    return list;
  };

  //это отслеживает смену адреса
  useEffect(async () => {
    if (!!!address.street) {
      let currenCity = citySearch(address.city_sync_id, cities);

      if (!!address.settlement_id) {
        let currentSettlements = citySearch(
          address.settlement_id,
          currenCity?.settlements
        );

        dispatch(updateOrderAddress("latitude", +currentSettlements?.latitude));
        dispatch(updateOrderAddress("longitude", +currentSettlements?.longitude));
      } else {
        dispatch(updateOrderAddress("latitude", +currenCity?.latitude));
        dispatch(updateOrderAddress("longitude", +currenCity?.longitude));
      }
    }
  }, [address.city_sync_id, address.settlement_id]);

  const handleChangeCoordinates = async (lat, lng) => {
    setConfirmAddressModal({
      isOpen: true,
      isLoad: false,
      lat,
      lng,
    });
  };

  const changeCoordinates = async () => {
    setConfirmAddressModal((prev) => ({
      ...prev,
      isLoad: true,
    }));

    const { lat, lng } = confirmAddressModal;
    const data = await getGeodataAddress(lat, lng, cityPolygonBounds);

    if (data) {
      let city = citySearchNameUa(data?.city, cities);

      if (city) {
        dispatch(updateOrderAddress("city_sync_id", city.sync_id));
      } else {
        city = suburdSearchNameUa(data?.city, settlementList());

        if (city) {
          dispatch(updateOrderAddress("settlement_id", city.sync_id));
        }
      }
      dispatch(updateOrderAddress("street", data.street));
      dispatch(updateOrderAddress("house_number", data.house_number));
    } else {
      dispatch(updateOrderAddress("street", ''));
      dispatch(updateOrderAddress("house_number", ''));
    }

    dispatch(updateOrderAddress("latitude", lat));
    dispatch(updateOrderAddress("longitude", lng));

    closeConfirmAddressModal();
  }

  const handleArea = (e) => {
    console.log(e);
  };

  const mapCenter = parseCoordinates(latitude, longitude, cities);
  return (
    <Wrapper>

      <DeliveryTimeButton>
        <ButtonIcon
          // onClick={() => searchForCoorinates(address, cities, dispatch)}
          title={`Орієнтовний час доставки: ${deliveryTime}`}
          icon={<ClockIcon />}
          positionIcon="left"
          isLoad={deliveryTimeLoading}
        />
      </DeliveryTimeButton>

      <MapDiv>
        <MapContainer
          style={defaultStyle}
          // zoomControlOptions={
          //   {
          //     position: google.maps.ControlPosition.RIGHT_CENTER,
          //   }
          // }
          center={mapCenter}
          zoom={13}
          options={defaultOptions}
        // onClick={(e) => handleChangeCoordinates(e.latLng.lat(), e.latLng.lng())}
        >
          <TileLayer
            attribution={false}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapCenterObserver center={mapCenter} />
          <MapMarkerLeaflet
            key={'markerOne'}
            onChange={handleChangeCoordinates}
            position={mapCenter}
            visible={!!(latitude && longitude)}
          />

          {
            searchActiveKitchenCityPolygons(address.city_sync_id, kitchensListCity)?.map((itemKitchen, indexKitchen) => (
              <div key={`wr_${indexKitchen}`}>
                <MapMarkerLeaflet
                  key={`marger_${indexKitchen}`}
                  position={{
                    lat: +itemKitchen.latitude,
                    lng: +itemKitchen.longitude,
                  }}
                  icone={iconHome}
                  clickable={false}
                  label={itemKitchen.title}
                />

                {
                  itemKitchen?.polygons?.map((itemPolygons, index) => (
                    <MapPolygonLeaflet
                      key={`mapPolygon_${index}`}
                      pathColor={!!itemKitchen.accepts_orders ? itemPolygons?.paths_color : "#000000"}
                      positions={itemPolygons?.paths}
                      title={itemKitchen?.title}
                      time={itemPolygons?.delivery_time}
                      startTime={itemPolygons?.start_time}
                      finishTime={itemPolygons?.finish_time}
                      marker={parseCoordinates(latitude, longitude, cities)}
                      handleArea={handleArea}
                    />
                  ))
                }
              </div>
            ))
          }

        </MapContainer>
      </MapDiv>

      {
        loadModalEdition.create && (
          <LoadingModal />
        )
      }

      <ModalDelete
        title={'Зміна адреси'}
        text={`Ви підтверджуєте зміну адреси ?`}
        isOpen={confirmAddressModal.isOpen}
        isLoad={confirmAddressModal.isLoad}
        funDelete={changeCoordinates}
        onCloseModal={closeConfirmAddressModal}
      />

    </Wrapper>
  );
};

const MapDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1 1 auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transform-origin: right top;
  height: 100%;
  top: 0;
  right: 460px;
  width: 460px;
  padding: 10px;
  background-color: ${(p) => p.theme.background};
  z-index: 10;
`;

const LoadMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const DeliveryTimeButton = styled.div`
  & button * {
    font-family: Barlow, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    margin: 0px 5px;
    color: black;
  }
`;

export default React.memo(TabMap);
