import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useKeyPress } from "hooks/useAddKey";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { typePrice } from "utils/helpers/price";
import { getTimeFlag } from "utils/helpers/getTimeFlag";
import { formatAddress } from "utils/helpers/formatAddress";
import { isArray, isObject, without } from "lodash";
import { finishedOrderStatuses } from "constants/finishedOrderStatuses";

const RowItem = ({
  data,
  item,
  price,
  bonus,
  typePayment,
  floor,
  userData,
  isTechnical = false,
  isSelected = false,
  noCall = false,
}) => {
  const theme = useTheme();
  // const [isUpdadeAdressStatusCycle, setIsUpdadeAdressStatusCycle] = useState(false);
  // const [isUpdateSttatusPayments, setIsUpdateSttatusPayments] = useState(false);

  const timeCook = getTimeFlag(item, 'delivery_till');

  // useEffect(() => {
  //   if ((item.user_id_change_address != userData.id) || (item.user_id_change_payments != userData.id)) {
  //     if (item.change_address > 0 || item.payments_changed > 0) {
  //       let timeCyccleW = setInterval(() => {
  //         if (item.change_address > 0) {
  //           setIsUpdadeAdressStatusCycle(prev => !prev);
  //         }

  //         if (item.payments_changed > 0) {
  //           setIsUpdateSttatusPayments(prev => !prev);
  //         }
  //       }, 2000);

  //       return () => clearInterval(timeCyccleW);
  //     } else {
  //       setIsUpdadeAdressStatusCycle(false);
  //       setIsUpdateSttatusPayments(false);
  //     }
  //   }

  // }, [data]);

  return (
    <Rows
      theme={theme}
      isUpdateAddress={false} // {isUpdadeAdressStatusCycle}
      isUpdateSttatusPayments={false} // {isUpdateSttatusPayments}
      isSelected={isSelected}
    >
      {
        isTechnical && (
          <div className="technical-info">Технічне замовлення</div>
        )
      }
      {
        noCall && (
          <div className="no-call-info">Не передзвонювати</div>
        )
      }
      <Item width={7} color={timeCook.class}>
        {
          Object.keys(finishedOrderStatuses).includes(item?.status) ? (
            <div className="closed_time">
              <i>{finishedOrderStatuses[item.status]}: </i>
              <div>
                {item.actual_status_time}
              </div>
            </div>
          ) : (
            <Time>{timeCook.time}</Time>
          )
        }
      </Item>
      <Item width={5}>
        <Name>{item.id}</Name>
      </Item>
      <Item width={6}>{item.restaurant_info.name}</Item>
      <Item width={7}>
        <Name>{item.client.name}</Name>
      </Item>
      <Item width={10}>{item.client.phone}</Item>
      <Item width={16}><div className="address">{formatAddress(item)}</div> </Item>
      <Item width={12}>{item?.operator_full_name}</Item>
      <Item width={13}>{item?.courier?.full_name || '-'}</Item>
      <Item isList={true} width={10}>
        <div className="payments">
          <ListPris>
            {
              isArray(typePayment) && typePayment.map((itemPa, i) => (
                <span key={i}>{itemPa}</span>
              ))
            }
          </ListPris>
        </div>
      </Item>
      <Item width={7}>-{item?.discount}</Item>
      <Item width={7}>{item?.to_pay}</Item>
    </Rows>
  )
}

const OrdersListObj = ({
  data,
  title,
  openEditorCallcenter,
  getCallcenterOrderItem,
  resetChangeAddress,
  idOrder,
  isKitchen = false,
  userData,
  openEditModal = () => { },
  bulkClose = () => { },
}) => {
  const theme = useTheme();

  const [isExpand, setExpand] = useState(true);
  const [selection, setSelection] = useState([]);
  const isKeyPressed = useKeyPress('Control');

  const handleOpenEditor = async (item, isChange) => {

    if (isKeyPressed && (item.status === "delivered"))
      return setSelection(function (previous) {
        const index = previous.indexOf(item.id);
        if (index === -1) {
          return [...previous, item.id];
        }

        const copy = [...previous];
        copy.splice(index, 1);

        return copy;
      });

    await getCallcenterOrderItem(item.id);

    openEditModal();
    await openEditorCallcenter(item, "kitchen");

    if (!!isChange)
      await resetChangeAddress(item.id);
  };

  const renderBody = () => {
    return without(Object.keys(data), "need_check").map((key) => {
      let itemArray = data[key];

      return isObject(itemArray[0]) && isArray(itemArray) && itemArray.map((item, index) => {
        let floor = item.address.floor ? `, кв. ${item.address.floor}` : '';
        let { price, bonus, typePayment } = typePrice(item.payments, false);
        let isTechnical = (item?.technical == 1);
        let noCall = (item?.no_call == 1);

        return (
          <div key={`orderItem_${index}`}>
            <Wr
              onClick={() => handleOpenEditor(item, item.change_address || item.payments_changed)}
              isOpen={idOrder == item.id}
            >
              {
                isKitchen && index == 0 && <NameKitchen>Кухня: {item.kitchen.title}</NameKitchen>
              }
              <RowItem
                item={item}
                price={price}
                bonus={bonus}
                typePayment={typePayment}
                floor={floor}
                data={data}
                userData={userData}
                isTechnical={isTechnical}
                isSelected={selection.includes(item.id)}
                noCall={noCall}
              />
            </Wr>
          </div>
        )
      })
    })
  };

  return (
    <Wrapper theme={theme}>
      <Titles onClick={() => {
        if (Object.keys(data).length > 0) {
          setExpand(!isExpand)
        }
      }} isExpand={isExpand}>
        {title}
        {
          (Object.keys(data).length > 0) && (
            <IconWr isExpand={isExpand}>
              <ArrowDownwardIcon />
            </IconWr>
          )
        }
      </Titles>

      {Object.keys(data).length ? (
        <Table isExpand={isExpand}>
          {renderBody()}
        </Table>
      ) : (
        <Text>Немає замовлень у цьому статусі</Text>
      )}

      {
        (selection.length > 0) && (
          <BtnFixedLeft>
            <ButtonIcon 
              title="Завершити вибрані замовлення" 
              colorBg="red" 
              onClick={() => {
                bulkClose(selection);
                setSelection([]);
              }} 
            />
          </BtnFixedLeft>
        )
      }

    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin-bottom: 15px;
                `;

const Text = styled.p`
                text-align: center;
                color: ${(p) => p.theme.lightAccentColor};
                `;

const Table = styled.div`
                display: ${(p) => (p.isExpand ? "table" : "none")};
                width: 100%;
                border-collapse: collapse;
                `;

const Wr = styled.div`
                margin-top: 16px;
                position: relative;
                z-index: ${(p) => p.isOpen ? 99 : 0}
                `;

const Titles = styled.div`
                font-weight: 600;
                font-size: 20px;
                color: ${(p) => p.theme.color25};
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                  color: ${(p) => p.theme.accentColor};
    }
                `;

const Rows = styled.div`
                display: flex;
                position: relative;
                align-items: center;
                background-color: ${(p) => (p.isSelected) ? "#d5b7b7" : (p.isUpdateAddress || p.isUpdateSttatusPayments) ? "#ffcf93" : "#fff"};
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-top: 16px;
                cursor: pointer;

                .address {
                  color: ${(p) => p.isUpdateAddress ? "red" : "#425466"};;
                  font-weight: ${(p) => p.isUpdateAddress ? "500" : "400"};;
                }

                .payments {
                  color: ${(p) => p.isUpdateSttatusPayments ? "orange" : "#425466"};
                  font-weight: ${(p) => p.isUpdateSttatusPayments ? "500" : "400"};
                }

                &:hover {
                  background-color: #E6E6E6;
                 }
                `;

const Item = styled.div`
                min-height: 56px;
                width: ${(p) => p.width}%;
                font-size: 14px;
                color: ${(p) => p.theme.color42};
                padding: 5px 5px 5px 5px;
                background: ${(p) => p.color};
                // white-space: nowrap;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px 0px 0px 8px;
    //             &:first-child {
    //               border-radius: 8px 0px 0px 8px;
    // }
    //             &:last-child {
    //               border-radius: 0px 8px 8px 0px;
    // }

    .closed_time {
      display: flex;
      flex-direction: column;
      i {
        color: green;
      }
    }
                `;

const ListPris = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Time = styled.div`
                width: 100%;
                height: 100%;
                border-radius: inherit;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                `;

const Name = styled.div`
               padding-left: 10px;
                `;

const IconWr = styled.span`
                svg {
                  transform: rotate(${(p) => p.isExpand ? "-180deg" : "0deg"});
  }
                margin-left: 20px;
                `;

const NameKitchen = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const BtnFixedLeft = styled.div`
  display: flex;
  position: fixed;
  width: 240px;
  left: 40px;
  bottom: 60px;
  z-index: 200;
  gap: 14px;
`;

export default React.memo(OrdersListObj);
