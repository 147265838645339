import React, { useMemo, useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";

import ModalEditor from "components/modals/ModalEditor";
import ModalToggleKitchenShift from "components/modals/ModalToggleKitchenShift";
import OrdersListObj from "./OrdersListObj";
import MenuPageMulti from "components/menuPageMulti";
import ToggleKitchenShiftButton from "./ToggleKitchenShiftButton";

import { HeadOrder } from "components/orders/HeadOrder";
import { dataItemsMenu } from 'constants/menuPageKitchens';
import { PrintCheck } from "components/PrintCheck";
import { Voice } from "components/voice";
import CalendarRange from "components/ui-kit/CalendarRange";
import { ModalLoad } from "components/modals/ModalLoad";
import ModalKitchenCounter from "components/modals/ModalKitchenCounter";
import ModalCourierReport from "components/modals/ModalCourierReport";
import ModalKitchenDeliveryTime from "components/modals/ModalKitchenDeliveryTime";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { InputSearch } from "components/ui-kit/inputs/InputSearch";

import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SearchIcon from "@mui/icons-material/Search";

import { activeInForceKitchenTabs } from "utils/helpers/activeInForce";
import { filterKitchenCodeOnId, isPicupKitchen } from "utils/helpers/filterApiParams";
import { kitchensName } from "utils/helpers/kitchens";
import { rangeConverDate } from "utils/helpers/date";
import { isArray, last } from "lodash";
import { isCloseKitchen } from "utils/helpers/adminKitchens";

// websocket
import { useWebsocket } from "hooks/useWebsocket";

const KitchenPage = ({
  // state
  orders,
  ordersCount,
  print,
  dataPrintAll,
  user,
  kitchens,
  isOpenModal,
  filter,
  profile,
  // redux
  getAllActiveOrders,
  addNotification,
  loadPage,
  getOrderPrint,
  updateFilter,
  openEditorCallcenter,
  updateOrder,
  updateOrderData,
  getDataPrintOrderAllKitchen,
  openModalToggleKitchenShift,
  //
  updateFormSearchOrders = () => { },
  handleІSybmitSearchOrders = () => { },
}) => {
  const dispatch = useDispatch();
  const echo = useWebsocket();

  const controlPlayer = useRef(undefined);
  const componentRef = useRef(undefined);

  let location = useLocation();
  let pathname = location.pathname;
  let keysMenu = Object.keys(dataItemsMenu);

  const [activeTabs, setActiveTabs] = useState([keysMenu[0]]);
  const [isEditingOrder, setIsEditingOrder] = useState(true);
  const [isBtnAdd, setBtnAdd] = useState(false);
  const [statePrint, setStatePrint] = useState(undefined);
  const [typePrint, setTypePrint] = useState("check");
  const [showModalCounter, setModalCounter] = useState(false);
  const [showModalCourierReport, setModalCourierReport] = useState(false);
  const [modalDeliveryTime, setModalDeliveryTime] = useState({ show: false, initiatedByUser: false });
  const { kitchenShiftStatus: { isShiftClosed }, kitchenShiftLoading } = useSelector(state => state.kitchens);
  const { searchValue, statusSearch } = useSelector(state => state.listings);

  useEffect(() => {
    let previouslyCheckedHour;
    const interval = setInterval(() => {
      const date = new Date();
      const hour = date.getHours();
      const minutes = date.getMinutes();

      if (minutes === 57 && (hour !== previouslyCheckedHour)) {
        setModalDeliveryTime({ show: true });
        previouslyCheckedHour = hour;
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const reactToPrintParams = useMemo(() => ({
    content: () => componentRef.current,
  }), []);

  const handlePrintCheck = useReactToPrint(reactToPrintParams);
  const handlePrint = useCallback(() => {
    setTypePrint("check");
    handlePrintCheck();
  }, [handlePrintCheck]);

  useEffect(() => {
    const channel = `orders.${user.user.kitchen_code}`;

    const event = ".order.assigned_to_kitchen";
    const printEvent = ".order.print";

    const listener = (justPrint) => ({ status, technical, id }) => {
      if (justPrint || (!["new", "pending"].includes(status) && !technical)) {
      
        if (!justPrint) {
          try {
            controlPlayer?.current && controlPlayer.current.play(`${status}_order`);
          } catch (error) { }
  
          addNotification({ war: ['Нове замовлення'] });
        }
        
        getOrderPrint(id).then(handlePrint);
      }
    }

    const assignedListener = listener(false);
    const printListener = listener(true);

    echo.private(channel).listen(event, assignedListener);
    echo.private(channel).listen(printEvent, printListener);

    return () => {
      echo.private(channel).stopListening(event, assignedListener)
      echo.private(channel).stopListening(printEvent, printListener)
    }

  }, [handlePrint, addNotification, getOrderPrint, echo, user.user.kitchen_code]);

  const handBtnDate = () => {
    getAllActiveOrders({ ...filterKitchenCodeOnId(user.user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false);
  }

  const openEditModal = () => {
    setIsEditingOrder(true);
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      handleІSybmitSearchOrders(statusSearch + 1);
    }
  }

  useEffect(() => {
    if (searchValue.length == 0) {
      handleІSybmitSearchOrders(0);
      updateFormSearchOrders("");
    }
  }, [searchValue]);

  const renderOrders = () => {
    return (
      <>
        <RowsH>
          <KithenTitle>Кухня: <span>{kitchensName(user.user.kitchens, kitchens)}</span></KithenTitle>
          <ToggleKitchenShiftButton />
          <ColH>
            <WrBtn>
              <InputSearch
                placeholder="Пошук замовлення"
                value={searchValue}
                onChange={(e) => dispatch(updateFormSearchOrders(e.target.value))}
                onKeyDown={(e) => onEnter(e)}
              />
              {
                statusSearch > 0 && (
                  <Ml>
                    <ButtonIcon
                      icon={<CleaningServicesIcon />}
                      colorBg="red"
                      onClick={() => {
                        handleІSybmitSearchOrders(0);
                        updateFormSearchOrders("");
                      }}
                    />
                  </Ml>
                )
              }
              <Ml>
                <ButtonIcon
                  icon={<SearchIcon />}
                  disabled={searchValue.length == 0}
                  onClick={() => { handleІSybmitSearchOrders(statusSearch + 1) }}
                />
              </Ml>
            </WrBtn>
            <Marginless>
              <CalendarRange
                isAbs={true}
                isStyle={true}
                valueIn={filter.date}
                ranges={filter.date}
                onChangeCal={(date) => updateFilter("date", [date.selection])}
                isBtn={true}
                handlerSubmit={handBtnDate}
                isDisabled={isShiftClosed || kitchenShiftLoading}
              />
            </Marginless>
          </ColH>
        </RowsH>

        <View>
          <HeadOrder />
        </View>

        {
          activeInForceKitchenTabs(keysMenu[0], activeTabs) && (
            <OrdersListObj
              key="cooking"
              data={orders.cooking || {}}
              title='Готуються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[1], activeTabs) && (
            <OrdersListObj
              key="preparing"
              data={orders.preparing || {}}
              title='Пакуються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[2], activeTabs) && (
            <OrdersListObj
              key="for_delivery"
              data={orders.for_delivery || {}}
              title='Очікують доставки'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[3], activeTabs) && (
            <OrdersListObj
              key="on_way"
              data={orders.on_way || {}}
              title='Доставляються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[4], activeTabs) && (
            <OrdersListObj
              key="pending_confirmed"
              data={orders.pending_confirmed || {}}
              title='Попереднi замовлення (оброблені)'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[5], activeTabs) && (
            <OrdersListObj
              key="rejected"
              data={orders.rejected || {}}
              title='Скасовані'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[6], activeTabs) && (
            <OrdersListObj
              key="delivered"
              data={orders.delivered || {}}
              title='Доставлені'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[7], activeTabs) && (
            <OrdersListObj
              key="closed"
              data={orders.closed || {}}
              title='Завершені'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[8], activeTabs) && (
            <OrdersListObj
              key="waiting_payment"
              data={orders.waiting_payment || {}}
              title='Попереднi замовлення (оброблені)'
              openEditModal={openEditModal}
            />
          )
        }
      </>
    );
  };

  const handleCreateOrder = () => {
    setIsEditingOrder(false);
    openEditorCallcenter(undefined, "kitchen");
    updateOrder(true);
    updateOrderData("pickup", "1");
  }

  const handlePrintAll = async () => {
    console.debug("Друкуємо увесь звіт...");

    await getDataPrintOrderAllKitchen();
    await setTypePrint("kitchen");
    await handlePrintCheck();

    // пропонуємо закрити зміну на кухні
    openModalToggleKitchenShift();
  }

  // розкоментувати після розробки модалки
  useEffect(() => {
    if (!isOpenModal) {
      let status = null;

      if (activeInForceKitchenTabs(dataItemsMenu['closed'].path, pathname)) {
        status = 'all';
      } else if (activeInForceKitchenTabs(dataItemsMenu['rejected'].path, pathname)) {
        status = 'all';
      }

      getAllActiveOrders({
        ...filterKitchenCodeOnId(user.user.kitchens, kitchens),
        ...rangeConverDate(filter.date),
        active_tabs: activeTabs,
      }, false, status);

      const interval = setInterval(() => {
        getAllActiveOrders({
          ...filterKitchenCodeOnId(user.user.kitchens, kitchens),
          ...rangeConverDate(filter.date),
          active_tabs: activeTabs,
        }, false, status);
      }, 20000);

      return () => clearInterval(interval);
    }
  }, [pathname, statusSearch, activeTabs, filter.date]);

  useEffect(() => {
    // setBtnAdd
    let arrKitchenProfile = filterKitchenCodeOnId(user.user.kitchens, kitchens, true);

    if (isArray(arrKitchenProfile)) {
      setBtnAdd(isPicupKitchen(arrKitchenProfile));
    }

    const interval = isCloseKitchen(user.user.kitchen_code, kitchens, setStatePrint);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (statePrint?.isAutoPrint) {
      handlePrintAll();
    }
  }, [statePrint])

  const menuItemsWithOrderCount = useMemo(() => {
    const entries = Object.entries(dataItemsMenu)
      .map(([key, value]) => (
        [key, {
          ...value,
          title: `${value.title} (${ordersCount[key] ?? 0})`
        }]
      ));
    return Object.fromEntries(entries);
  }, [ordersCount]);

  return (
    <>
      <Wrapper>
        <MenuPageMulti
          items={menuItemsWithOrderCount}
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
          isBtnAdd={isBtnAdd}
          handleCreateOrder={handleCreateOrder}
          isBtnPrint={true}
          isBtnKitchenCounter={true}
          isBtnCourierReport={true}
          isBtnKitchenDeliveryTime={true}
          handlePrintAll={handlePrintAll}
          handleBtnKitchenCounter={() => setModalCounter(true)}
          handleBtnCourierReport={() => setModalCourierReport(true)}
          handleBtnKitchenDeliveryTime={() => setModalDeliveryTime({ show: true, initiatedByUser: true })}
        />
        {orders && (
          <WrOrders>
            {renderOrders()}
          </WrOrders>
        )}
        <ModalEditor
          isEditingOrder={isEditingOrder}
          handlePrint={handlePrint}
          isKitchen={true}
          isEditDeliver={false}
          isMap={false}
          isDeliveryOnlyPicup={true}
        />

        <ModalToggleKitchenShift />
        <PrintCheck data={(typePrint == "check") ? print : dataPrintAll} typeCheck={typePrint} ref={componentRef} />

        <Voice controlPlayer={controlPlayer} />
        {
          loadPage && (
            <ModalLoad />
          )
        }
        {
          showModalCounter && (
            <ModalKitchenCounter
              title={'Щоденний кухонний звіт'}
              isOpen={showModalCounter}
              onCloseModal={() => setModalCounter(false)}
            // isLoad={isLoadModalSaveEditAddress}
            />
          )
        }
        {
          showModalCourierReport && (
            <ModalCourierReport
              title={'Звіт за кур\'єрами'}
              isOpen={showModalCourierReport}
              onCloseModal={() => setModalCourierReport(false)}
            />
          )
        }
        {
          modalDeliveryTime.show && (
            <ModalKitchenDeliveryTime
              isOpen
              title={'Редагування часу доставки'}
              initiatedByUser={modalDeliveryTime.initiatedByUser}
              onCloseModal={() => setModalDeliveryTime({ show: false })}
            />
          )
        }
      </Wrapper>
    </>
  );
};

const ColH = styled.div`
  margin-left: auto;
  padding-left: 20px;
  display: flex;
`;

const RowsH = styled.div`
  display: flex;
  align-items: center;
`;

const KithenTitle = styled.div`
  font-size: 16px;
  span {
    font-weight: 700;
  }
`;

const Wrapper = styled.div`
  padding: 5px;
  padding-bottom: 80px;
`;

const View = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`;

const WrOrders = styled.div`
  margin-top: 14px;
`;

const Ml = styled.div`
  margin-left: 10px;
`;

const Marginless = styled.div`
  & *:not(input) {
    margin: 0;
    padding: 0;
  }
`;

const WrBtn = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;
`;

export default React.memo(KitchenPage);
