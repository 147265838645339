import moment from "moment";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import { Shipment } from "pages/Statistic/Orders/KitchenDeliveryTimes/KitchenDeliveryTimes";

const boxSx = {
  borderRadius: "10px",
  border: "1px solid #DEDEDE",
  padding: 1,
}

const DeliveryTimeHistory = ({ history, restaurant }) => (
  <Stack component={Box} spacing={1} sx={boxSx}>
    {history.map((entry) => (
      <Box key={entry.id} sx={boxSx}>

        <Stack direction="row" justifyContent="space-between" paddingBottom={1}>
          <b>{entry.user?.full_name}</b>
          {moment(entry.updated_at).format("YYYY-MM-DD HH:mm:ss")}
        </Stack>

        <Divider flexItem />

        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1} justifyContent="space-evenly">
          <Shipment
            restaurant={restaurant}
            time={entry.delivery_time}
            type="delivery"
            label="Доставка"
          />
          <Shipment
            restaurant={restaurant}
            time={entry.delivery_time}
            type="pickup"
            label="Самовивіз"
          />
        </Stack>
      </Box>
    ))}
  </Stack>
);

export default DeliveryTimeHistory;
