import { isArray, cloneDeep, isString } from "lodash";

import { getFiltet } from "utils/processingRequester/getLocalFilterOrders";

import {
  updateFormReasonStatus,
  getUpdateOrder,
} from "./order";
import { updateLoadModalEdition } from "./loading";
import {
  closeEditorCallcenter,
  redactionOrder,
  setIdOrderCook
} from "./view";
import { getAllActiveOrders } from "./listings";
import { getOrderByStatusForChef } from './cook';
import { filterKitchenCodeOnId } from "utils/helpers/filterApiParams";
import { rangeConverDate } from "utils/helpers/date";
import { removeUnnecessaryTableware } from "utils/helpers/orders";
import { getAdditionPrice } from "utils/helpers/price";

import api from "../../apiSingleton";

import {
  GET_ITEMS_TYPES_SUCCESS,
  GET_ORDER_ITEMS_SUCCESS,
  GET_ORDERS_SUCCESS,
  GET_STATUSES_SUCCESS,
  GET_TYPES_SUCCESS,
  REMOVE_ACTIVE_ORDER,
  SET_ACTIVE_ORDER,
  SET_ACTIVE_ORDER_STATUS,
  UPDATE_SEARCH_QUERY,
} from "../constants/orders";

import { GET_ITEM_ORDER_BY_ID } from "../constants/order";

import {
  addNotification
} from './notifications';

import { fixedStartDate } from "utils/helpers/date";

export function orderCreate(order, isKitchen = false, isMovig = false) {
  return async (dispatch, state) => {
    const {
      user: {
        user
      },
      settings: {
        kitchens
      },
      order: {
        reason
      },
      view: {
        modalCallCenter: { source }
      },
      listings: {
        filter
      }
    } = state();

    let client = {};

    if (!!order.client.id) client.id = order.client.id;
    client.source = order.client.source;
    client.name = order.client.name;
    client.phone = order.client.phone;

    order.client.sex && (client.sex = order.client.sex);
    (order.client.birthday != fixedStartDate) && (client.birthday = order.client.birthday);

    const sendOrder = {
      restaurant: order.restaurant,
      technical: order.technical,
      kitchen_code: order.kitchen_code,
      payment_type: "cash",
      client_rest: order.client_rest,
      type: order.type,
      status: "cooking",
      return_call: 0,
      client_comment: order.client_comment,
      client,
      method: 'call-center',
      delivered_till: order.delivered_till,
      stock_id: order.stock_id == 0 ? null : order.stock_id,
      pickup: order.pickup,
      ignore_stock: +order.ignore_stock,
      intercom: order.intercom,
      corps: order.corps,
      delivery_type_id: order.delivery_type_id,
      address: {
        ...order.address,
        latitude: String(order.address.latitude),
        longitude: String(order.address.longitude),
      },
      items: order.items,
      payments: order.payments,
      discounts: order.discounts.length > 0 ? order.discounts : null,
      persons: order.persons,
      cutlery_sets: removeUnnecessaryTableware(order.cutlery_sets),
      source,
    };

    const citySyncId = sendOrder.address.city_sync_id;
    const deliveryTypeId = sendOrder.delivery_type_id;

    sendOrder.items = sendOrder.items?.map(function (item) {
      return {
        ...item,
        additions: item.additions?.map(function (itemAddition) {
          return { 
            ...itemAddition, 
            price: getAdditionPrice(itemAddition, citySyncId, deliveryTypeId),
          }
        }),
      }
    });

    if (isKitchen) {
      // по цьому полю розрізняємо звідки нове поле - з кухні чи ні 
      sendOrder.status_kitchen_check = 1;
    }

    if (client?.source?.code) {
      sendOrder.client.source = client?.source?.code;
    }

    if (!order?.client?.id) delete sendOrder?.client.id;

    if (isMovig) {
      sendOrder.kitchen_code = reason.kitchen_transferring;
    }

    if (isString(order.type)) {
      if (order.type.includes("soon")) {
        sendOrder.delivered_till = null;
      }
    }

    await dispatch(updateLoadModalEdition(true));

    const result = await api.orders.createOrder(sendOrder);

    if (!!result.data && !!result.data.id) {
      if (isKitchen) {
        await dispatch(getAllActiveOrders({ ...filterKitchenCodeOnId(user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false));
      } else {
        await dispatch(getAllActiveOrders(getFiltet()));
      }

      await dispatch(updateLoadModalEdition(false));
      await dispatch(closeEditorCallcenter());
      await dispatch(addNotification({ suc: ["Нове замовлення створено"] }));
    } else {
      await dispatch(updateLoadModalEdition(false));
      await dispatch(addNotification(result.errors, 'error'));
    }
  };
}

export function updateOrderStatus(id, status = "cooking") {
  return async (dispatch) => {
    dispatch(updateLoadModalEdition(true));

    const result = await api.orders.updateOrderStatus(id, status);
    if (!!result.status) {
      dispatch(addNotification({ war: [`Статус замовлення №${id} оновлено`] }));
    } else {
      dispatch(addNotification(result.errors, 'error'));
    }

    dispatch(updateLoadModalEdition(false));
    dispatch(closeEditorCallcenter());
  }
}

export function orderUpdate(order, status = "cooking", isKitchen = false) {
  return async (dispatch, state) => {
    const {
      user: {
        user
      },
      settings: {
        kitchens
      },
      view: {
        modalCallCenter: { source }
      },
      listings: {
        filter
      }
    } = state();

    let client = {
      ...order.client,
    };

    // TODO
    const sendOrder = {
      restaurant: order.restaurant,
      technical: order.technical,
      kitchen_code: order.kitchen_code,
      payment_type: order.payment_type,
      client,
      client_rest: order.client_rest,
      type: order.type,
      courier_id: order?.courier_id || null,
      status: status,
      stock_id: order.stock_id == 0 ? null : order.stock_id,
      return_call: order.return_call,
      client_comment: order.client_comment,
      delivered_till: order.delivered_till,
      pickup: order.pickup,
      ignore_stock: +order.ignore_stock,
      intercom: order.intercom,
      corps: order.corps,
      delivery_type_id: order.delivery_type_id,
      address: {
        ...order.address,
        latitude: String(order.address.latitude),
        longitude: String(order.address.longitude),
      },
      items: order.items,
      payments: order.payments,
      discounts: order.discounts.length > 0 ? order.discounts : null,
      persons: order.persons,
      cutlery_sets: removeUnnecessaryTableware(order.cutlery_sets),
      source,
    };

    const citySyncId = sendOrder.address.city_sync_id;
    const deliveryTypeId = sendOrder.delivery_type_id;

    sendOrder.items = sendOrder.items?.map(function (item) {
      return {
        ...item,
        additions: item.additions?.map(function (itemAddition) {
          return { 
            ...itemAddition, 
            price: getAdditionPrice(itemAddition, citySyncId, deliveryTypeId),
          }
        }),
      }
    });

    if (isKitchen) {
      // по цьому полю розрізняємо звідки нове поле - з кухні чи ні 
      sendOrder.status_kitchen_check = 1;
    }

    if (order.client.source?.code) {
      sendOrder.client.source = order.client.source.code;
    }

    await dispatch(updateLoadModalEdition(true));

    const result = await api.orders.updateOrder(order.id, sendOrder);

    if (!!result.data && !!result.data.id) {
      if (isKitchen) {
        await dispatch(getAllActiveOrders({ ...filterKitchenCodeOnId(user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false));
      } else {
        await dispatch(getAllActiveOrders(getFiltet()));
      }

      await dispatch(getUpdateOrder(result.data));
      await dispatch(updateLoadModalEdition(false));

      await dispatch(addNotification({ war: ['Готово'] }));
    } else {
      await dispatch(updateLoadModalEdition(false));
      await dispatch(addNotification(result.errors, 'error'));
    }
  };
}

export function orderReject(order, isKitchen = false) {
  return async (dispatch, state) => {
    const {
      user: {
        user
      },
      settings: {
        kitchens
      },
      listings: {
        filter
      }
    } = state();

    let client = {
      ...order.client,
    };

    let sendOrder = {
      ...order,
      client,
      status: "rejected",
      stock_id: order.stock_id == 0 ? null : order.stock_id,
      ignore_stock: +order.ignore_stock,
      discounts: order.discounts.length > 0 ? order.discounts : null,
      address: {
        ...order.address,
        latitude: String(order.address.latitude),
        longitude: String(order.address.longitude),
      },
    };

    delete sendOrder.discounts;

    sendOrder.client.source = order.client.source?.code;

    await dispatch(updateLoadModalEdition(true));

    const result = await api.orders.updateOrder(order.id, sendOrder);

    if (!!result.data && !!result.data.id) {
      // Let's not refetch orders for now. We have interval
      // if (isKitchen) {
      //   await dispatch(getAllActiveOrders({ ...filterKitchenCodeOnId(user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false));
      // } else {
      //   await dispatch(getAllActiveOrders(getFiltet()));
      // }

      await dispatch(updateLoadModalEdition(false));
      await dispatch(closeEditorCallcenter());
    } else {
      await dispatch(updateLoadModalEdition(false));
      await dispatch(addNotification(result.errors, 'error'));
    }
  };
}

export function resetChangeAddress(id) {
  return async (dispatch, state) => {
    const {
      user: {
        user
      },
      settings: {
        kitchens
      },
      listings: {
        filter
      }
    } = state();

    try {
      const res = await api.orders.resetChangeAddress(id);
      await dispatch(getAllActiveOrders({ ...filterKitchenCodeOnId(user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false));
    } catch (error) {
      console.log("resetChangeAddress: ", error);
    }
  }
}

export function initializeOrders() { // unused
  return async (dispatch) => {
    dispatch(getOrders());
  };
}

export function getOrders() { // unused (or used somewhere very deep in the code)
  return async (dispatch) => {
    const { data: orders } = await api.orders.getOrders();

    await dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: { orders },
    });
  };
}

export function getStatuses() {
  return async (dispatch) => {
    const { data: statuses } = await api.orders.getStatuses();

    await dispatch({
      type: GET_STATUSES_SUCCESS,
      payload: { statuses },
    });
  };
}

export function getTimeTypes() {
  return async (dispatch) => {
    const { data: timeTypes } = await api.orders.getTimeTypes();

    await dispatch({
      type: GET_TYPES_SUCCESS,
      payload: { timeTypes },
    });
  };
}

export function getItemsTypes() {
  return async (dispatch) => {
    const { data: itemsTypes } = await api.orders.getItemsTypes();

    await dispatch({
      type: GET_ITEMS_TYPES_SUCCESS,
      payload: { itemsTypes },
    });
  };
}

export function getOrderItems() {
  return async (dispatch) => {
    const { data: items } = await api.orders.getOrderItems();

    await dispatch({
      type: GET_ORDER_ITEMS_SUCCESS,
      payload: { items },
    });
  };
}

export function setActiveOrder(order) {
  return {
    type: SET_ACTIVE_ORDER,
    payload: { order },
  };
}

export function setActiveOrderStatus(status) {
  return {
    type: SET_ACTIVE_ORDER_STATUS,
    payload: { status },
  };
}

export function removeActiveOrder() {
  return { type: REMOVE_ACTIVE_ORDER };
}

export function updateItemComment(comment, itemId) {
  return async (dispatch, getState) => {
    const items = cloneDeep(getState().orders.items);
    const itemIndex = items.findIndex((el) => el.id === itemId);

    items[itemIndex].comment = comment;

    await dispatch({
      type: GET_ORDER_ITEMS_SUCCESS,
      payload: { items },
    });
  };
}

export function updateItem(id, status) {
  return async (dispatch) => {
    await api.orders.updateItem(id, status);
    await dispatch(getOrderItems());
  };
}

export function updateOrdersSearchQuery(value) {
  return {
    type: UPDATE_SEARCH_QUERY,
    payload: { value },
  };
}

export function startDelivery(terminal, orders) {
  return async () => {
    await api.orders.startDelivery(terminal, orders);
  };
}

export function completeDelivery(id, params) {
  return async () => {
    await api.orders.completeDelivery(id, params);
  };
}

export function getCallcenterOrderItem(id, obj = {}) {
  return async (dispatch, state) => {
    let stateUser = state()?.user?.user;
    let { isNew } = obj;

    await dispatch(updateLoadModalEdition(true));

    let { data } = await api.orders.getCallcenterOrderItem(id, { format: "form" });

    if (isArray(data?.users)) {
      if (data?.users.length > 0) {

        data?.users.map((user) => {
          if (user.role_name == stateUser.role_name) {
            if (user.id != stateUser.id) {
              dispatch(redactionOrder(false));
            }
          }
        })
      }
    }

    if (isNew) {
      data = {
        ...data,
        ...{
          id: null,
          status: "draft",
          promocode: null,
        }
      }
    }

    await dispatch({
      type: GET_ITEM_ORDER_BY_ID,
      payload: data,
    });

    await dispatch(updateLoadModalEdition(false));
  };
}

export function updateStatus(id, status) {
  return async (dispatch) => {
    await dispatch(setIdOrderCook(id));
    await api.orders.updateStatus(id, status);
    await dispatch(setIdOrderCook(null));
    await dispatch(getOrderByStatusForChef());
  }
}

export function bulkClose(ids) {
  return async (dispatch) => {
    const response = await api.orders.bulkClose(ids);
    if (!response.status || response.error) {
      return dispatch(addNotification({ err: ["Виникла помилка"] }));
    }
    dispatch(addNotification({ suc: ["Готово"] }));
  }
}

export function createReason(isKitchen = false) {
  return async (dispatch, state) => {
    let { order } = state();

    dispatch(updateFormReasonStatus("isLoadModalReason", true));

    const obj = { ...order.reason };

    if (obj.write_off_products == 'no') {
      delete obj.guilty;
    }

    if (obj.write_off_products == "no" || obj.write_off_products == "yes") {
      delete obj.scrapped_products;
    }

    const res = await api.orders.createReason(order.data.id, obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ suc: ["Готово"] }));
      await dispatch(orderReject(order.data, isKitchen));
      await dispatch(updateFormReasonStatus("isOpenModalReason", false));
      await dispatch(closeEditorCallcenter());

      let isMovig = 'transferring_to_another_kitchen' == order.reason.reason;

      if (isMovig) {
        await dispatch(orderCreate(order.data, isKitchen, isMovig));
      }
    }

    await dispatch(updateFormReasonStatus("isLoadModalReason", false));
  };
}

